import axios from "axios";
import router from "../router";
import { ElMessage } from "element-plus";

const instance = axios.create({
  baseURL: "/api/seller-english-service",
  timeout: 5000,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      ElMessage.warning("登录状态失效，请重新登录");
      localStorage.removeItem("token");
      router.replace("/login");
    }

    return Promise.reject(err);
  }
);

export default instance;
