<script setup></script>

<template>
  <div class="foot_basediameter">
    <div class="footer">
      <!--第一部分-->
      <div class="bottom_row_one">
        <div class="botton_row_one_container">
          <!--左-->
          <div class="one-left">
            <span>Method of use</span
            ><!--使用指南-->
            <div class="znan">Usage Guide</div>
          </div>

          <!--中-->
          <div class="one-center">
            <div class="one-center-once">
              <span>Related to changes in company information：</span>
              <span><u>admin@filterap.com</u></span>
              <span>&nbsp;(File size does not exceed 15MB)</span>
            </div>

            <div class="one-center-two">
              <span>telephone：</span>
              <span><u>0318-7561509</u></span>
              <span>Service time：</span>
              <span><u>8:00～18:00</u></span>
            </div>

            <div class="one-center-three">
              <span>complaints and suggestions：</span>
              <span><u>click here</u></span>
            </div>
          </div>

          <!--右-->
          <div class="one-right">
            <div class="right-container">
              <div class="right-img">
                <img src="../../assets/index/short_duration1.webp" alt="" />
                <div class="iner">
                  <span>Short lead time</span>
                </div>
              </div>
              <div class="right-img">
                <img src="../../assets/index/low_price2.webp" alt="" />
                <div class="iner">
                  <span>Low price</span>
                </div>
              </div>
              <div class="right-img">
                <img src="../../assets/index/high_quality3.webp" alt="" />
                <div class="iner">
                  <span>High quality</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--第二部分-->
      <div class="bottom_row_two">
        <div class="botton_row_two_container">
          <!--上-->
          <div class="container_top">
            <span>Inquiry Download</span>
            <span>|</span>
            <span>Financial information download</span>
            <span>|</span>
            <span>Account application form download</span>
            <span>|</span>
            <span>Company Information</span>
          </div>

          <!--中-->
          <div class="container_center">
            <span>Rules of use</span>
            <span>|</span>
            <span>Customer Information Protection</span>
            <span>|</span>
            <span>Website Map</span>
            <span>|</span>
            <span>Company Outline</span>
            <span>|</span>
            <span>Job Information</span>
            <span>|</span>
            <span>Company outline</span>
            <span>|</span>
            <span>code of conduct</span>
          </div>

          <!--下-->
          <div class="container_bottom">
            <span
              >Associated website：<span
                >Anping County Runrong Metal Wire Mesh Products</span
              ></span
            >
          </div>
        </div>
      </div>

      <!--第三部分-->
      <div class="bottom_row_three">
        <div class="bottom_row_three_container">
          <div class="container_three">
            <span>冀ICP备17009166号-1</span>
            <span>|</span>
            <span>About us</span>
            <span>|</span>
            <span>Contact service</span>
            <span>|</span>
            <span>Legal Notice</span>
            <span>|</span>
            <span>Intellectual Property</span>
            <span>|</span>
            <span>Sincerely recruit talents</span>
          </div>

          <div class="center_three">
            <span>Electronic Business License</span>
            <span>|</span>
            <span>Permission Privacy Policy</span>
            <span>|</span>
            <span
              >Copyright © 2021 Anping County Runrong Metal Wire Mesh
              Products</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
