<script setup>
import { ChatLineSquare, Search, ShoppingCart } from "@element-plus/icons-vue";
import { ref } from "vue";
import router from "../../router";
import { onMounted } from "vue";
import { getAllCategoryDataInfoByStatePop } from "../../api/category";
import { adminUserId } from "../../router";
import { ElMessage } from "element-plus";
import { transCategoryDataToTree } from "../../utils/formdate";
import { getUserName } from "../../api/shopUser";

//声明输入搜索框中的
const selectInputSearch = ref("");

//全部分类菜单
const menuList = ref();

//登录的用户名
const userName = ref(undefined);

//发送请求获取所有的分类数据
const getAllCategoryData = async () => {
  await getAllCategoryDataInfoByStatePop({
    uuid: adminUserId,
  })
    .then((res) => {
      console.log(res, "res");
      if (res.data.code === -1) {
        ElMessage.error("出现错误，请稍后重试");
      } else if (res.data.code === 0) {
        let data = transCategoryDataToTree(res.data.data, "0");
        let newData = data.map((item, i) => {
          item.menuIndex = i;
          if (item.children.length > 0) {
            item.children.map((jItem, j) => {
              jItem.menuIndex = i + "-" + j;
              return jItem;
            });
          }
          return item;
        });
        let navList = [
          { category_name: "Home", menuIndex: "-1", url: "/index" },
          {
            category_name: "All Categories",
            menuIndex: "-2",
            children: newData,
          },
          { category_name: "Inquiry Quotation", menuIndex: "-3" },
          { category_name: "Proxy Brand", menuIndex: "-4" },
          { category_name: "Document Center", menuIndex: "-5" },
          { category_name: "Merchants Join", menuIndex: "-6" },
          { category_name: "Technical Support", menuIndex: "-7" },
          { category_name: "About Us", menuIndex: "-8", url: "/about" },
          {
            category_name: "Contact Us",
            menuIndex: "-9",
            url: "/concact",
          },
          { category_name: "Customer Service", menuIndex: "-10" },
        ];

        menuList.value = navList;
        console.log("菜单", menuList.value);
      }
    })
    .catch((err) => {});
};

//获取用户名

//页面加载时就执行的函数
onMounted(async () => {
  //发送请求获取所有的分类数据
  getAllCategoryData();
  const token = localStorage.getItem("token");
  if (token) {
    await getUserName().then((res) => {
      if (res.data.code == 0) {
        userName.value = res.data.data.userName;
      } else {
        userName.value = undefined;
      }
    });
  } else {
    userName.value = undefined;
  }
});
// 点击注册，跳转到注册页面
const goRegister = () => {
  router.push({ path: "/login" });
};

// 点击右上角的个人中心跳转到个人中心页面
const goPersonalCenter = () => {
  router.push({ path: "/personal" });
};

//当点击了一级分类之后
const clickCategoryOne = (name) => {
  router.push({ path: "/product", query: { productName: name } });
};

//点击具体的商品之后商品之后跳转到详情页

const goPageTwo = () => {
  router.push({ path: "/detail" });
};

//点击进入购物车
const shopCart = () => {
  router.push("/shopCart");
};
</script>

<template>
  <div class="top_container">
    <div class="container_top">
      <div class="content">
        <div class="content_top">
          <div class="contentTop_left" v-if="userName == undefined">
            <el-link :underline="false" class="link" @click="goRegister"
              >Sign in</el-link
            >
            <span class="span">|</span>
            <el-link :underline="false" class="span1">Sign up</el-link>
          </div>
          <div class="contentTop_left" v-else>
            <span class="sideText">Welcome:</span>
            <span class="topLeftName">{{ userName }}</span>
          </div>

          <div class="contentTop_center">
            <ul>
              <li>Message</li>
              <li>Order</li>
              <li @click="goPersonalCenter">Personal center</li>
              <li>Custom service</li>
              <li>Get the app</li>
            </ul>
          </div>
        </div>

        <div class="content_bottom">
          <div class="cintainer">
            <img src="../../assets/index/logo.png" alt="" />

            <div class="ipt">
              <el-input
                style="width: 600px; position: relative"
                placeholder="Please input"
                class="input-with-select"
                size="large"
              >
                <template #prepend>
                  <el-select
                    v-model="selectInputSearch"
                    placeholder="All categories"
                    style="width: 150px; border: none"
                  >
                    <el-option label="All categories" value="0" />
                    <el-option label="Filter Element" value="1" />
                    <el-option label="Filter cartridge" value="2" />
                    <el-option label="Filter Disk" value="3" />
                  </el-select>
                </template>
                <template #append>
                  <el-button :icon="Search" />
                </template>
              </el-input>

              <div class="cin">
                <ul>
                  <li>Metal filter element</li>
                  <li>Filter cartridge</li>
                  <li>Punching net</li>
                  <li>Sintered mesh</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="tainer">
            <el-button style="width: 140px; height: 40px" color="#003399">
              <el-icon>
                <ChatLineSquare />
              </el-icon>
              &nbsp;inquiry / enquiry
            </el-button>

            <el-button
              style="width: 140px; height: 40px; color: #333333"
              color="#FFCC00"
              @click="shopCart"
            >
              <el-icon>
                <ShoppingCart />
              </el-icon>
              &nbsp;Shopping Cart
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="container_bottom">
      <div style="width: 95%; margin: auto">
        <el-menu
          default-active="/index"
          class="el-menu-demo"
          background-color="#4A4A4A"
          text-color="#fff"
          mode="horizontal"
          active-text-color="#FFBF00"
          :router="true"
          style="width: 1260px; margin: auto; height: 32px"
        >
          <template v-for="item in menuList" :key="item.menuIndex">
            <el-sub-menu
              v-if="item.children && item.children.length > 0"
              :index="item.url || `default-index-${item.menuIndex}`"
            >
              <template #title>{{ item.category_name }}</template>

              <el-menu-item
                v-for="jItem in item.children"
                :index="`${jItem.menuIndex}`"
                :key="jItem.categoryId"
              >
                <el-sub-menu
                  v-if="jItem.children && jItem.children.length > 0"
                  :index="`${jItem.menuIndex}`"
                >
                  <template
                    #title
                    @click="clickCategoryOne(jItem.categoryName)"
                    >{{ jItem.categoryName }}</template
                  >

                  <el-menu-item
                    v-for="kItem in jItem.children"
                    :index="`${jItem.menuIndex}`"
                    :key="kItem.menuIndex"
                  >
                    <template #title @click="goPageTwo">{{
                      kItem.categoryName
                    }}</template>
                  </el-menu-item>
                </el-sub-menu>

                <el-menu-item v-else :index="jItem.menuIndex">
                  <template #title>{{ jItem.categoryName }}</template>
                </el-menu-item>
              </el-menu-item>
            </el-sub-menu>

            <el-menu-item v-else :index="item.url">
              <template #title>{{ item.category_name }}</template>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>
