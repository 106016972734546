import request from "../utils/request";

// 发送请求向指定邮箱发送验证码√
export const sendMail = (data) => request.post("/shopUsers/sendMail", data);

// 发送请求注册账户√
export const shopUserRegister = (data) =>
  request.post("/shopUsers/shopUserRegister", data);

// 发送请求登录账户√
export const shopUserLogin = (data) =>
  request.post("/shopUsers/shopUserLogin", data);

//获取用户名√
export const getUserName = () => request.get("/shopUsers/getUserName");
