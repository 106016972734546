//获取当前日期时间
export function getCurrentDataTime() {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let Hours = date.getHours();
  let Minutes = date.getMinutes();
  let Seconds = date.getSeconds();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  if (Hours < 10) {
    Hours = "0" + Hours;
  }
  if (Minutes < 10) {
    Minutes = "0" + Minutes;
  }
  if (Seconds < 10) {
    Seconds = "0" + Seconds;
  }
  return (
    year + "-" + month + "-" + day + " " + Hours + ":" + Minutes + ":" + Seconds
  );
}

//将标准时间转成合适的日期格式
export function transStandardToFormat(theDate) {
  let year = theDate.getFullYear();
  let month = theDate.getMonth() + 1;
  let day = theDate.getDate();
  let Hours = theDate.getHours();
  let Minutes = theDate.getMinutes();
  let Seconds = theDate.getSeconds();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  if (Hours < 10) {
    Hours = "0" + Hours;
  }
  if (Minutes < 10) {
    Minutes = "0" + Minutes;
  }
  if (Seconds < 10) {
    Seconds = "0" + Seconds;
  }
  return (
    year + "-" + month + "-" + day + " " + Hours + ":" + Minutes + ":" + Seconds
  );
}

//把全部的分类数据转换成树形数据
export function transCategoryDataToTree(list, rootValue) {
  const arr = [];

  list.forEach((item) => {
    if (item.categoryPid === rootValue) {
      //找到了匹配的节点
      arr.push(item);

      //当前节点的id 和 当前节点的子节点的pid是相等的
      item.children = transCategoryDataToTree(list, item.categoryId);
      //将子节点赋值给当前节点
    }
  });
  return arr;
}

//把对象数组中按照一个属性进行分组
export const groupByName = (array, key) => {
  return array.reduce((result, currentItem) => {
    // 使用 key 函数提取分组的键，如果不提供，则直接使用属性
    const groupKey =
      typeof key === "function" ? key(currentItem) : currentItem[key];

    // 确保 result 对象中有对应分组的数组
    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    // 将当前项添加到对应分组的数组中
    result[groupKey].push(currentItem);

    return result;
  }, {});
};
