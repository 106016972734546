<template>
  <div>
    <CepHaloSome></CepHaloSome>
    <router-view></router-view>
    <BaseDiameter></BaseDiameter>
  </div>
</template>

<script setup></script>
<style></style>
