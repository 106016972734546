import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/index.vue";

export const adminUserId = "e0ba71b0-f863-11ee-aa34-6740f80d5348";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "",
        component: () => import("../views/BodyEmulsion/index.vue"),
      },
      {
        path: "/detail",
        component: () => import("../personal/detail/index.vue"),
      },
      {
        path: "/product",
        component: () => import("../personal/product/index.vue"),
      },
      {
        path: "/concact",
        component: () => import("../personal/concact/index.vue"),
      },
      {
        path: "/payview",
        component: () => import("../personal/payview/index.vue"),
      },
      {
        path: "/shopCart",
        component: () => import("../personal/shopcart/index.vue"),
      },
      {
        path: "/favorites",
        component: () => import("../personal/favorites/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("../views/Login/index.vue"),
  },
  // 关于
  {
    path: "/about",
    component: () => import("../personal/about.vue"),
    children: [
      {
        path: "",
        component: () => import("../personal/aboutHome/index.vue"),
      },
      {
        path: "/operate",
        component: () => import("../personal/OperatingGuide/index.vue"),
      },
    ],
  },
  // 会员中心
  {
    path: "/personal",
    component: () => import("../personal/personal.vue"),
    children: [
      {
        path: "",
        component: () => import("../personal/Home/index.vue"),
      },
      {
        path: "/purchase",
        component: () => import("../personal/purchase/index.vue"),
      },
      {
        path: "/browsingHistory",
        component: () => import("../personal/browsingHistory/index.vue"),
      },

      {
        path: "/messageCenter",
        component: () => import("../personal/messageCenter/index.vue"),
      },
      {
        path: "/personalInformation",
        component: () => import("../personal/personalInformation/index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.path === "/") {
    next();
  } else if (!token && to.path !== "/login") {
    next("/login");
  } else {
    next();
  }
});

export default router;
