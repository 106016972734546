import { defineStore } from "pinia";
import { getInfotheme } from "../api/theme";

export const useThemeStore = defineStore("theme", {
  state: () => ({
    currentTheme: "theme-yellow",
  }),
  actions: {
    async setTheme(themeName, url) {
      this.currentTheme = themeName;
      localStorage.setItem("currentTheme", themeName);
      const themeStyle = document.getElementById("theme-style");

      let themePath;

      if (import.meta.env.MODE === "development") {
        themePath = `./src/${themeName}.less`;
      } else {
        themeName = themeName.replace("-", "_");
        themePath = `${themeName}.css`;
      }

      themeStyle.setAttribute("href", themePath);
      document.body.className = themeName;
      console.log(`使用主题 ${themeName}`);

      // let res = await getInfotheme(url);
      // console.log(res, "res");
    },
    async applySavedTheme() {
      const savedTheme = localStorage.getItem("currentTheme");
      if (savedTheme) {
        this.setTheme(savedTheme);
        console.log(`应用本地存储中的主题 ${savedTheme}`);
      } else {
        this.setTheme("theme-yellow");
        console.log("使用默认主题 theme-yellow");
      }
    },
  },
});
