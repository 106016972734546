import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import { themePlugin } from "./plugins/themePlugin";
const app = createApp(App);
import { createPinia } from "pinia";
const pinia = createPinia();
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "normalize.css";
import "./assets/icon-font/iconfont.css";
const lessFiles = import.meta.glob("@/theme-*.less");
Object.keys(lessFiles).forEach((path) => {
  lessFiles[path]();
});

app.use(router);
app.use(pinia);
app.use(themePlugin);
app.mount("#app");
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
