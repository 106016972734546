import request from "../utils/request";

//获取所有的分类数据√
export const getAllCategoryDataInfoByStatePop = () =>
  request.post("/category/getAllCategoryDataInfoByStatePop");

//获取所有的一级分类√
export const getAllCategoryDataInfoOne = () =>
  request.post("/category/getAllCategoryDataInfoOne");

//根据pid 获取一级分类下的所有二级分类√
export const getCategoryDataByPid = (data) =>
  request.post("/category/getCategoryDataByPid", data);

//根据一级分类数组，，，，，然后返回中数组中每个一级分类下边包括商品信息不超过十条√
export const getDataInfoByCategoryOne = (data) =>
  request.post("/category/getDataInfoByCategoryOne", data);
